<template>
  <div></div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "Logout",
  methods: {
    logout() {
      this.$auth
        .logout()
        .then(() => {
          console.log("Nenhum usuário conectado...");
          this.$store.commit("User/logout");
          this.$sweetAlerts.toast1("success", "Até mais...");
        })
        .catch(error => {
          console.log("Erro na tentativa de Logout!", error);
          this.$sweetAlerts.toast1("error", "Algo deu errado!");
        });
    }
  },
  mounted() {
    this.logout();
  }
};
</script>
